<template>
  <div id="app" class="h-screen">
    <wedding-page />
  </div>
</template>

<script>
import WeddingPage from '@/components/WeddingPage'

export default {
  name: 'App',
  components: {
    WeddingPage,
  }
}
</script>
<template>
<div class="text-white text-2xl wedding-page pb-20">
  <div class="flex items-center justify-center flex-col">
    <h1 class="font-bold text-4xl xl:text-6xl mt-8 leading-tight text-center">
      Connor & Michelle
    </h1>
    <div class="mt-8">October 24, 2020</div>
    <div>2:00 pm ET</div>
    <div class="my-8 w-3/4 lg:w-3/5 xl:w-2/5">
      <div class='embed-container'><iframe src='https://www.youtube.com/embed/6L57A7ztkP4' frameborder='0' allowfullscreen></iframe></div>
    </div>
    <div class="text-xl text-center px-4">
      Tune in <span class="font-bold">Saturday October 24, 2020 at 2:00 pm ET</span> for live stream
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>


<style scoped>
  h1 {
    font-family: 'Dancing Script', cursive;
  }

  .wedding-page {
    font-family: 'Roboto', sans-serif;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
